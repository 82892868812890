import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import GooglePayButton from '@google-pay/button-react';
import { stripeAPIKey, hostEnv, googleMerchantId } from 'reactAppConstants';

const GooglePayComponent = ({ isDisabled, total, handleLoadPaymentData }) => {

  useEffect(() => {
    const checkAndUpdateButton = () => {
      try {
        const gpayButton = document.getElementById('gpay-button-online-api-id');
        if (gpayButton) {
          gpayButton.disabled = isDisabled;
          if (isDisabled) {
            gpayButton.style.backgroundColor = '#D9D9D9';
          } else {
            gpayButton.style.backgroundColor = '';
          }
        }
      } catch (error) {
        console.error('Error updating GPay button:', error);
      }
    };

    checkAndUpdateButton();

    const observer = new MutationObserver(checkAndUpdateButton);

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, [isDisabled]);

  const formatCentsToDollars = (value) => {
    value = (value + '').replace(/[^\d.-]/g, '');
    value = parseFloat(value);
    return value ? value / 100 : 0;
  };

  const paymentRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        type: 'CARD',
        parameters: {
          allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
          allowedCardNetworks: ['MASTERCARD', 'VISA'],
        },
        tokenizationSpecification: {
          type: 'PAYMENT_GATEWAY',
          parameters: {
            gateway: 'stripe',
            'stripe:version': '2020-08-27',
            'stripe:publishableKey': stripeAPIKey,
          },
        },
      },
    ],
    merchantInfo: {
      merchantId: googleMerchantId,
      merchantName: 'Methodology',
    },
    transactionInfo: {
      totalPriceStatus: 'FINAL',
      totalPriceLabel: 'Total',
      totalPrice: total && formatCentsToDollars(total.amount).toString(),
      currencyCode: 'USD',
      countryCode: 'US',
    },
  };

  if (!total) {
    return null;
  }

  return (
    <GooglePayButton
      environment={hostEnv === 'production' ? 'PRODUCTION' : 'TEST'}
      buttonColor='default'
      buttonType='plain'
      buttonSizeMode='fill'
      paymentRequest={paymentRequest}
      onLoadPaymentData={handleLoadPaymentData}
      style={{ width: '100%', height: 40 }}
    />
  );
};

GooglePayComponent.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  total: PropTypes.object.isRequired,
  handleLoadPaymentData: PropTypes.func.isRequired,
};

export default GooglePayComponent;
